import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Number Input",
  "type": "Form"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Number Input`}</strong>{` component is a specific type of `}<a parentName="p" {...{
        "href": "/form/text-input/"
      }}><strong parentName="a">{`Text Input`}</strong></a>{`
component, that is used to render an input box for number input.`}</p>
    <p>{`It has underneath an instance of
`}<a parentName="p" {...{
        "href": "https://github.com/s-yadav/react-number-format#readme"
      }}>{`react-number-input`}</a>{` and
supports several combinations of it.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true",
        "live": "true",
        "editor": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
  <NumberInput
    defaultValue={0}
    unit="€"
    textAlign="right"
    decimalScale={2}
    fixedDecimalScale
  />
</div>
`}</code></pre>
    <h2>{`Props`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Number Input`}</strong>{` passes unused props to the underlying
`}<a parentName="p" {...{
          "href": "/form/text-input/"
        }}><strong parentName="a">{`Text Input`}</strong></a>{` component.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Label rendered with the input field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`helper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message rendered on the right of the label ( with `}<em parentName="td">{`label`}</em>{` prop only )`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message displayed on the bottom`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"error" / "success"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of message for visual feedback`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`modified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shows an orange border around`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon rendered to the left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rightIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon rendered to the right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftBlock`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Block rendered on the left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rightBlock`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Block rendered to the right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textAlign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"left" / "right"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"right"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The alignment of the text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`unit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unit that this number input is going to be associated with`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`decimalScale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If defined it limits to given decimal scale`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fixedDecimalScale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If true it add 0s to match given decimalScale`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`allowNegative`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allow negative numbers (Only when format option is not provided)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`allowEmptyFormatting`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Apply formatting to empty inputs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`allowLeadingZeros`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allow leading zeros at beginning of number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`format`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If format given as hash string allow number input inplace of hash. If format given as function, component calls the function with unformatted number and expects formatted number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`mask`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If mask defined, component will show non entered placed with masked value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onValueChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`(values) => {}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`onValueChange handler accepts values object`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isAllowed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`(values) => boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A checker function to check if input value is valid or not`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      